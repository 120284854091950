<template>
  <div v-if="authUserIsCoach" class=" container-xxl">
    <div class="row">
      <div class="col">
        <ProgramList class="my-3"></ProgramList>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Programs',
  components: {
    ProgramList: () => import('@/controllers/program/ProgramList'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
  },
};
</script>
